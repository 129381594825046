import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { VictoryChart, VictoryLine, VictoryGroup, VictoryAxis } from 'victory';

const dateOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'short',
  day: '2-digit',
};

function nFormatter(num, digits) {
  var si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
}

const ProductionChart = ({ seriesData }) => {
  const chartData = seriesData.DATA;
  const updated = seriesData.ENTRY_DATE;
  const color = seriesData.STYLE.COLOR;

  return (
    <Fragment>
      <div>
        <div>
          <VictoryChart
            domainPadding={{ x: 5, y: 5 }}
            scale={{ x: 'time', y: 'linear' }}
          >
            <VictoryGroup
              data={
                chartData.length > 0
                  ? chartData.map((point) => ({
                      x: point[0] * 1000,
                      y: point[1],
                    }))
                  : []
              }
            >
              <VictoryLine
                style={{
                  data: {
                    stroke: `#${color}`,
                    strokeWidth: 3,
                  },
                  labels: {
                    fill: `#${color}`,
                    fontSize: 24,
                    fontWeight: 800,
                    fontFamily: 'Helvetica',
                  },
                }}
              />
            </VictoryGroup>
            <VictoryAxis
              crossAxis
              style={{
                axis: { stroke: '#9CA3AF' },
                tickLabels: {
                  fill: '#9CA3AF',
                  fontSize: 14,
                  fontWeight: 900,
                  fontFamily: 'Roboto',
                  tickCount: 2,
                },
              }}
            />
            <VictoryAxis
              dependentAxis
              tickFormat={(t) => nFormatter(t, 3)}
              style={{
                axis: { stroke: '#9CA3AF' },
                tickLabels: {
                  fill: '#9CA3AF',
                  fontSize: 14,
                  fontWeight: 900,
                  fontFamily: 'Roboto',
                  tickCount: 2,
                },
              }}
            />
          </VictoryChart>
        </div>
        <span className='block text-base text-yellow-700 font-light tracking-tight text-center'>
          {`Last updated: ${new Date(updated).toLocaleDateString(
            'en-US',
            dateOptions
          )}`}
        </span>
      </div>
    </Fragment>
  );
};

ProductionChart.propTypes = {
  seriesData: PropTypes.object.isRequired,
};

export default ProductionChart;
