import React from 'react';
import PropTypes from 'prop-types';
import DataTable, { createTheme } from 'react-data-table-component';

createTheme('solarized', {
  text: {
    primary: '#FFFFFF',
    secondary: '#FFFFFF',
  },
  background: {
    default: 'transparent',
  },
  context: {
    background: '#cb4b16',
    text: '#FFFFFF',
  },
  divider: {
    default: '#FFFFFF',
  },
});

const customStyles = {
  rows: {
    style: {
      minHeight: '48px',
    },
  },
  headCells: {
    style: {
      fontSize: 16,
      paddingLeft: '16px', // override the cell padding for head cells
      paddingRight: '16px',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
    },
  },
};

const DataRoomTable = ({ data }) => {
  const columns = [
    {
      name: 'Series ID',
      selector: 'series id',
      sortable: true,
      maxWidth: '600px',
      cell: (row) => (
        <div className='text-sm font-light tracking-tight text-white justify-right'>
          {data.SERIES_ID}
        </div>
      ),
    },
    {
      name: 'Units',
      selector: 'units',
      sortable: true,
      maxWidth: '600px',
      right: true,
      cell: (row) => (
        <div className='text-sm font-light tracking-tight text-white'>
          {data.UNITS}
        </div>
      ),
    },
    {
      name: 'Source',
      selector: 'source',
      sortable: true,
      maxWidth: '600px',
      right: true,
      cell: (row) => (
        <div className='text-sm font-light tracking-tight text-white'>
          {data.SOURCE}
        </div>
      ),
    },
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      maxWidth: '600px',
      right: true,
      cell: (row) => (
        <div className='text-sm font-light tracking-tight text-white'>
          {new Date(row[0] * 1000).toLocaleDateString('en-US')}
        </div>
      ),
    },
    {
      name: `${data.NAME}`,
      selector: `${data.NAME}`,
      sortable: true,
      maxWidth: '600px',
      right: true,
      cell: (row) => (
        <div className='text-sm font-light tracking-tight text-white'>
          {row[1]}
        </div>
      ),
    },
  ];

  return (
    <div className='mx-auto justify-center'>
      <DataTable
        title={
          <div className='justify-center text-3xl font-extrabold tracking-tight text-white py-4'>
            raw data
          </div>
        }
        columns={columns}
        data={data.DATA}
        theme='solarized'
        customStyles={customStyles}
        dense
        fixedHeader
        fixedHeaderScrollHeight='50vh'
        pagination
        overflowY
      />
    </div>
  );
};

DataRoomTable.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DataRoomTable;
