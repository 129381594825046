import React from 'react';
import Layout from '../../components/layout/Layout';
import DataRoomDashboard from '../../components/layout/DataRoomDashboard';

const App = () => {
  return (
    <Layout>
      <div className='flex-grow'>
        <div className='bg-transparent'>
          <div className='lg:max-w-7xl mx-auto px-4 mt-4'>
            <div className='py-6 text-center'>
              <h1 className='text-lg text-yellow-700 font-semibold tracking-wide uppercase'>
                recent data
              </h1>
              <h2 className='mt-2 text-6xl font-extrabold tracking-tight text-white'>
                data room
              </h2>
              <h3 className='mt-4 max-w-2xl mx-auto text-2xl font-normal text-gray-400'>
                aggregated public energy data sources - a Greenfield project
              </h3>
            </div>
          </div>
        </div>
        <div className='lg:max-w-7xl mx-auto px-4 mt-4'>
          <DataRoomDashboard />
        </div>
      </div>
    </Layout>
  );
};
export default App;
