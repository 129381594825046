import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Ticker from 'react-ticker';

const HeaderTextChart = ({ headlines }) => {
  console.log(headlines);
  return (
    <Fragment>
      <Ticker>
        {({ index }) => (
          <div className='lg:text-2xl text-md font-bold text-white'>
            {headlines[0].TEXT}
          </div>
        )}
      </Ticker>
    </Fragment>
  );
};

HeaderTextChart.propTypes = {
  headlines: PropTypes.array.isRequired,
};

export default HeaderTextChart;
