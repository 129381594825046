import api from './api';
import {
  GET_DATAROOM_TIMESERIES,
  GET_DATAROOM_TIMESERIES_ERROR,
  GET_DATAROOM_HEADLINES,
  GET_DATAROOM_HEADLINES_ERROR,
} from './types';

// Get timeseries data for dataroom charts
export const getDataRoomTimeseries = () => async (dispatch) => {
  try {
    const res = await api.get(`/eiatimeseries/all`);
    dispatch({
      type: GET_DATAROOM_TIMESERIES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_DATAROOM_TIMESERIES_ERROR,
      payload: { msg: 'Error fetching all EIA timeseries' },
    });
  }
};

// Get headlines for news ticker
export const getDataRoomTickerHeadlines = () => async (dispatch) => {
  try {
    const res = await api.get(`/tickerheadline/`);
    dispatch({
      type: GET_DATAROOM_HEADLINES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_DATAROOM_HEADLINES_ERROR,
      payload: { msg: 'Error fetching news ticker headlines' },
    });
  }
};
