import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getDataRoomTimeseries,
  getDataRoomTickerHeadlines,
} from '../../actions/dataroom';
import Spinner from '../status/Spinner';
import ProductionChart from '../charting/ProductionChart';
import DataRoomTable from '../table/DataRoomTable';
import HeaderTextChart from '../charting/HeaderTextChart';

const DataRoomDashboard = ({
  getDataRoomTimeseries,
  getDataRoomTickerHeadlines,
  dataroom: { timeseries, headlines, loading },
}) => {
  useEffect(() => {
    getDataRoomTimeseries();
  }, [getDataRoomTimeseries]);

  useEffect(() => {
    getDataRoomTickerHeadlines();
  }, [getDataRoomTickerHeadlines]);

  const [dataTableData, setDataTableData] = useState({});

  return (
    <Fragment>
      {loading ? (
        <div className='grid lg:grid-rows-3 gap-6 py-4'>
          <div className='py-5 bg-white shadow-2xl rounded-3xl'>
            <Spinner />
          </div>
        </div>
      ) : (
        <div>
          {headlines ? (
            <div className='block mx-auto py-5 bg-gray-500 shadow-2xl rounded-xl'>
              <HeaderTextChart headlines={[headlines]} />
            </div>
          ) : (
            <div />
          )}
          <div className='grid lg:grid-cols-3 md:grid-cols-2 gap-2 py-4'>
            {timeseries.map((item) => (
              <button
                key={item._id}
                className='py-5 bg-white shadow-2xl rounded-3xl focus:outline-none focus:ring-4 focus:ring-yellow-700 '
                onClick={() => {
                  setDataTableData(item);
                }}
              >
                <div className='block justify-center px-4'>
                  <span className='block text-center text-gray-700 font-black text-2xl'>
                    {`${item.NAME}, ${item.UNITS}`}
                  </span>
                  <div className='block h-5/6 text-3xl font-extrabold tracking-tight text-gray-700'>
                    <ProductionChart seriesData={item} />
                  </div>
                </div>
              </button>
            ))}
          </div>
          <div className='block h-1/2 rounded-3xl bg-gray-500 p-4'>
            <DataRoomTable data={dataTableData} />
          </div>
          <h2 className='px-2 mt-2 text-base font-base tracking-tight text-white'>
            Source: EIA, Baker Hughes
          </h2>
        </div>
      )}
    </Fragment>
  );
};

DataRoomDashboard.propTypes = {
  getDataRoomTimeseries: PropTypes.func.isRequired,
  getDataRoomTickerHeadlines: PropTypes.func.isRequired,
  dataroom: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  dataroom: state.dataroom,
});

export default connect(mapStateToProps, {
  getDataRoomTimeseries,
  getDataRoomTickerHeadlines,
})(DataRoomDashboard);
